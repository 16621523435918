import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";


export class Start extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            info: '+++++++++++',
            service: [],
            dateTime: '',
            activeBlock: '',
            activeCompany: [],
            recordsPrev: [],

        };


    }


    componentDidMount() {


    }


    render() {
        console.log(this.props.Links)
        return (
            <div className={'global'}>
                <div className={'preloader'}></div>
                <div className={'block-link'}>

                    <a href={this.props.Links.yandex} className={'link yandex'}>Яндекс</a>
                    <a href={this.props.Links.twogis} className={'link twogis'}>2Гис</a>
                    <a href={this.props.Links.google} className={'link google'}>Google</a>

                </div>
            </div>
        )


    }
}

export default Start;