import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Start from "../Start/Start";


export class Stars extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            step: 1,
            phone: '',
            text: '',
            links: [],
            smile: null,
            isLoad: false,


        };

        this.changePhone = this.changePhone.bind(this);
        this.changeText = this.changeText.bind(this);
    }

    changePhone(event) {
        let val = event.target.value;
        val = val.replace(/[^+\d]/g, '');


        let num = `${val.substring(0, 1)} ${val.substring(1, 4)} ${val.substring(4, 7)} ${val.substring(7, val.length)}`;

        num = num.trim();

        if (num.length <= 14) {
            this.setState({phone: num});
        }

        if (num.length == 14) {
            this.setState({btnPhone: true});
        } else {
            this.setState({btnPhone: false});
        }


    }

    componentDidMount() {


        const data2 = {
            hash: this.props.Hash,

        };


        axios({

            url: GlobalVariable.host() + '/open/review/show',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data2,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    links: result.data,
                    isLoad: true
                })


            }).catch(error => {

            // window.location.href = '/' + this.props.widgetId + '/profile';
        })

        let data = sessionStorage.getItem("reactions");
        let data3 = sessionStorage.getItem("reactionsSend");
        if(!data3){
            if (data) {
                // if (Number(data) > 3) {
                //     this.setState({step: 3});
                // } else {
                //     this.setState({step: 2});
                // }

            }
        }


    }

    changeText(e) {

        this.setState({text: e.target.value});
    }

    sendReactionsToServer() {
        const data = {
            smile: this.state.smile,
            text: this.state.text,
            hash: this.props.Hash,
            phone: this.state.phone.replace(/[^0-9]/g, "")
        };


        axios({

            url: GlobalVariable.host() + '/open/review/bad',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({step: 4});
                sessionStorage.setItem("reactionsSend", 'true');
            }).catch(error => {

            // window.location.href = '/' + this.props.widgetId + '/profile';
        })
    }

    sendReactions(i) {
        sessionStorage.setItem("reactions", i);
        let tempStep = 3;
        if (i < 4) {
            tempStep = 2;
        }
        this.setState({smile: i, step: tempStep})
    }

    renderButton() {
        if (this.state.phone.length == 14) {
            return (
                <div onClick={()=>this.sendReactionsToServer()} className={'btn-active mt-3 mb-3'}>
                    Отправить
                </div>
            )
        } else {
            return (
                <div className={'btn-no-active mt-3 mb-3'}>
                    Отправить
                </div>
            )
        }
    }

    renderBadReactions() {
        return (
            <div className={'global'}>
                <div className={'badReactions'}>

                    <div className={'title'}>Пожалуйста, оставьте свой отзыв и мы разберемся в проблеме
                    </div>
                    <div className={'form'}>
                        <div className={'small-title'}>Опишите ваш визит</div>
                        <div className={'text'}>
                            <textarea value={this.state.text} onChange={this.changeText}  placeholder="Место для отзыва"/>
                        </div>
                        <div className={'small-title'}>Оставьте свой номер телефона для обратной связи</div>
                        <div className={'phone'}>
                            <input onChange={this.changePhone} style={{paddingLeft: '73px'}} value={this.state.phone}
                                   placeholder={'7 (xxx) xxx-xx-xx'}
                                   className={'input-phone '}/>
                        </div>

                    </div>

                    {this.renderButton()}
                </div>
            </div>
        )
    }


    render() {

        if (this.state.isLoad == false) {
            return (
                <div className={'global'}>
                    <div className={'loader'}></div>
                </div>
            )
        }
    else if (this.state.step == 1) {
    return (
                <div className={'global'}>
                <div className={'block-stars'}>

                    <div className={'title'}>Оцените качество сервиса</div>
                    <div className={'reactions'}>
                        <div onClick={() => this.sendReactions(1)} className={'block-reaction-item'}><img
                            src={'/img/stars1.png'}/></div>
                        <div onClick={() => this.sendReactions(2)} className={'block-reaction-item'}><img
                            src={'/img/stars2.png'}/></div>
                        <div onClick={() => this.sendReactions(3)} className={'block-reaction-item'}><img
                            src={'/img/stars3.png'}/></div>
                        <div onClick={() => this.sendReactions(4)} className={'block-reaction-item'}><img
                            src={'/img/stars4.png'}/></div>
                        <div onClick={() => this.sendReactions(5)} className={'block-reaction-item'}><img
                            src={'/img/stars5.png'}/></div>
                    </div>


                </div>
    </div>
    )
    } else
        if (this.state.step == 2) {
            return (
                this.renderBadReactions()
            )

        } else if (this.state.step == 3) {

            return (<Start Links={this.state.links}></Start>)

        }
        else if (this.state.step == 4) {

            return (
                <div className={'global'}>
                    <div className={''}>Благодарим за отзыв!</div>
                </div>
            )

        }



    }
}

export default Stars;