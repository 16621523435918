import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.css';
import Global from "./components/Global";
import Start from "./components/Start/Start";
import Stars from "./components/Stars/Stars";


ReactDOM.render(
    <Router>

        <Switch>


            <Route path="/:hash" children={<HomeFunc/>}/>
        </Switch>
    </Router>,
    document.getElementById('root')
);

function HomeFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let {hash} = useParams();


    return <Stars Hash={hash}/>;
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
